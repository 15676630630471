import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import './style.css';
import Login from './pages/Login';
import Home from './pages/Home';
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import Redirect from "./Redirect";
import Logout from "./pages/Logout";
import { useEffect } from "react";
import { userDetail } from "./store/actions/usersAction";

function App() {
  const loader = useSelector(state => state?.users?.loader)

  const token = useSelector(state => state?.users?.token)

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(userRedux?.token) {
  //     dispatch(userDetail({email: 'test@test.com'}))
  //   }
  // }, [userRedux?.token])

  return (
    <BrowserRouter>
      {loader && <div className="loaderContainer">
        <span className="loader"></span>
      </div>}
      <Routes>
        {/* <Route path="/" element={<Redirect to="/home" />} /> */}
        {token ? (
          <>
            <Route path="*" element={<Redirect to="/dashboard" />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
          </>
        ) : (
          <>
          <Route path="*" element={<Redirect to="/" />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
    // <Home />
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
