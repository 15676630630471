import VinShikLogo from '../images/logo.svg';
import JavaImg from '../images/java.svg';
import ReactImg from '../images/react.svg';
import logoFooter from '../images/logo-footer.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Dashboard() {
    const userDetail = useSelector(state => state.users.user);
    return (
        <>
        <header class="pt-4 pb-4 sticky">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-5 my-auto">
                    <a href="#">
                        <img src={VinShikLogo} alt="" />
                    </a>
                </div>
                <div class="col-md-9 col-7 text-right my-auto">
                    Welcome {userDetail?.firstname} ! <Link to={'/logout'} class="btn btn-dark ml-md-4 ml-3">Logout</Link>
                </div>
            </div>
        </div>
    </header>
    {
        userDetail?.status === 'Pending' &&
        <div className='pendingBar'>
            <div className='container'>
            Thank you for register on VinShik. Your account is in pending state. <br />
            Your account will be activate soon after validate your detail. Please waiting for activation to access other features
            </div>
        </div>
    }
    <div className='userDetail bg-light border-top'>
        <div className='container'>
            <div class="row">
                <div class="col-6">
                    <b>First Name:</b> {userDetail?.firstname}
                </div>
                <div class="col-6">
                    <b>Last Name:</b> {userDetail?.lastname}
                </div>
                <div class="col-6">
                    <b>Email:</b> {userDetail?.email}
                </div>
                <div class="col-6">
                    <b>Phone:</b> {userDetail?.phone}
                </div>
                <div class="col-6">
                    <b>Locaiton:</b> {userDetail?.location}
                </div>
                <div class="col-6">
                    <b>Status:</b> {userDetail?.status}
                </div>
            </div>
        </div>
    </div>
    {userDetail?.status === 'active' ? 
    <div className='course-container border-top'>
        <div className='container'>
            <h1>React JS Training</h1>
            <h2>JavaScript</h2>
            <p>What is JavaScript?</p>
            <p>JavaScript for browsers</p>
            <p>Working of JavaScript Engine</p>
            <p>Variable Declarations<br />--Var<br />--Let<br />--Const</p>
            <p>Data Types</p>
            <p>Objects in JS</p>
            <p>Different ways to create new object</p>
            <p>Arrays in JS</p>
            <p>Functions</p>
            <p>Arrow Functions</p>
            <p>Equality Operators</p>
            <p>Ternary Operators</p>
            <p>Spread Operator</p>
            <p>Map() in JS</p>
            <p>Filter() in JS</p>
            <h2>React JS</h2>
            <p>Introduction</p>
            <h2>Redux</h2>
            <p>Introduction</p>
        </div>
    </div>
    : <div style={{height: '200px'}}></div>}
    <div class="top-services bg-light pt-5 pb-5 border-top">
        <div class="container pt-md-4 pb-md-4">
            <div class="row">
                <div class="col-12">
                    <div class="bg-white p-lg-5 p-4 rounded box-shadow">
                        <div class="row">
                            <div class="col-lg-3 my-lg-auto mb-lg-0 mb-4 text-lg-left text-center">
                                <h2>Top Services</h2>
                            </div>
                            <div class="col-lg-3 col-md-4 mb-md-0 mb-4 text-md-left text-center">
                                <img src={JavaImg} alt="" />
                                <h4><a href="#">Java Script</a></h4>
                            </div>
                            <div class="col-lg-3 col-md-4 mb-md-0 mb-4 text-md-left text-center">
                                <img src={ReactImg} alt="" />
                                <h4><a href="#">React JS</a></h4>
                            </div>
                            <div class="col-lg-3 col-md-4 mb-md-0 mb-4 text-md-left text-center">
                                <img src={JavaImg} alt="" />
                                <h4><a href="#">Redux</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="pt-5">
        <div class="container-fluid">
            <div class="container">
                <div class="row bottom">
                    <div class="col-md-6 col-6 mb-5">
                        <p style={{marginBottom: '10px'}}>
                            <a href="#">
                                <img src={logoFooter} alt="" />
                            </a>
                        </p>
                        <p>2023 Copyright Reserved Vinshik</p>
                    </div>
                    <div class="col-md-6 col-6 mb-5 text-md-right">
                        <h6>Follow us on</h6>
                        <div class="social">
                            <a href="#"><i class="fa fa-linkedin-square" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
    )
}

export default Dashboard;