import { createAsyncThunk } from "@reduxjs/toolkit";
// import DataService from "../../../store/service";
import AxiosInstance from "../axios";
import { constant } from "../constant";
const { path } = constant;


export const userLogin = createAsyncThunk(
  "users/userLogin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/users/login", arg, rejectWithValue),
);

export const userDetail = createAsyncThunk(
  "users/userDetail",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/users/userdetail", arg, rejectWithValue),
);

export const userCreate = createAsyncThunk(
  "users/userCreate",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/users/", arg, rejectWithValue),
);