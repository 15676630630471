import { createSlice } from "@reduxjs/toolkit";
import {
  userCreate,
  userDetail,
  userLogin,
} from "../actions/usersAction";

export const users = createSlice({
  name: "users",
  initialState: {
    user: null,
    loader: false,
    userDetail: null,
    apiResponse: null,
  },
  reducers: {
    setToken: (state, payload) => {
      state.token = payload?.payload;
    },
    setUser: (state, payload) => {
      console.log('payload-', payload)
      state.user = payload?.payload;
    },
    userReset: (state) => {
      state.error = null;
      state.token = null;
      state.user = null;
    },
    setLoader: (state, payload) => {
      state.loader =  payload?.payload;
    },
    setApiResponse: (state, payload) => {
      state.apiResponse = payload?.payload
    }
  },
  extraReducers: builder => {
    console.log('builder-', builder)
    var { pending, fulfilled, rejected } = userLogin;

    builder.addCase(pending, (state, action) => {
      state.loader = true;
      console.log('state-', state, action?.payload?.data)
    })
    builder.addCase(fulfilled, (state, action) => {
      state.loader = false;
      console.log('state-', state, action?.payload?.data)
      const data = action?.payload?.data;
      if(data?.success === 0){
        state.error = data?.data;
        state.apiResponse = {
          type: 'error',
          message: data?.data
        }
        state.user = null;
      } else {
        state.error = null;
        state.user = data?.user;
        state.token = data?.token;
        localStorage.setItem('token', data?.token)
      }
    })
    builder.addCase(rejected, (state, action) => {
      state.loader = false;
      console.log('state-', state, action?.payload?.data)
    })

    var { pending:userPending, fulfilled: userFulfilled, rejected: userRejected } = userDetail;

    builder.addCase(userPending, (state, action) => {
      state.loader = true;
      console.log('state-', state, action?.payload?.data)
    })
    builder.addCase(userFulfilled, (state, action) => {
      state.loader = false;
      console.log('state-', state, action?.payload?.data)
      const data = action?.payload?.data;
      if(data?.success === 0){
        state.error = data?.data;
        state.user = null;
        state.token = null;
      } else {
        state.error = null;
        state.user = data?.data;
      }
    })
    builder.addCase(userRejected, (state, action) => {
      state.loader = false;
      console.log('state-', state, action?.payload?.data)
    })

    var { pending: createPending, fulfilled: createFulfilled, rejected: createRejected } = userCreate;

    builder.addCase(createPending, (state, action) => {
      state.loader = true;
      console.log('state-', state, action?.payload?.data)
    })
    builder.addCase(createFulfilled, (state, action) => {
      state.loader = false;
      console.log('state-', state, action?.payload?.data)
      const data = action?.payload?.data;
      if(data?.success === 0){
        state.error = data?.data;
        state.user = null;
        state.apiResponse = {
          type: 'error',
          message: data?.message
        }
      } else {
        state.error = null;
        state.user = data?.user;
        state.token = data?.token;
        localStorage.setItem('token', data?.token)
        state.apiResponse = {
          type: 'success',
          message: "User is successfully created"
        }
      }
    })
    builder.addCase(createRejected, (state, action) => {
      state.loader = false;
      console.log('state-', action)
    })

    // [userLogin.pending]: (state, payload) => {
    //   state.error = null;
    //   state.isLoader = true;
    // },
    // [userLogin.fulfilled]: (state, { payload }) => {
    //   state.error = null;
    //   console.log("payload", payload);
    //   return {
    //     ...state,
    //     ...payload?.data?.data,
    //     isLoader: false,
    //   };
    // },
    // [userLogin.rejected]: (state, { payload }) => {
    //   state.error = payload;
    //   state.isLoader = false;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser, userReset, setLoader, setApiResponse } = users.actions;

export default users.reducer;