import { useDispatch } from "react-redux";
import { userReset } from "../store/reducerSlice/users";

function Logout() {
  localStorage.removeItem('token');
  const dispatch = useDispatch();
  dispatch(userReset());
  return null;
}

export default Logout;