import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import VinShikLogo from '../VinShik.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userCreate } from '../store/actions/usersAction';
import { setApiResponse } from '../store/reducerSlice/users';
// import './App.css';

function SignUp() {
    const [user, setUser] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        location: '',
        password: '',
        confirmPassword: '',
        category: 'React JS',
        status: 'Pending'
      });
      const [error, setError] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        location: '',
        password: '',
        confirmPassword: ''
      });

      const apiResponse = useSelector(state => state?.users?.apiResponse);

  const updateUser = (e, key) => {
    console.log('-e-', e.target.value);
    const value = e?.target?.value.trim()
    setUser({
        ...user,
        [key]: value
    })
    if(key === "email") {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter email address'
            })
        } else if(!value.match(regex)){
            return setError({
                ...error,
                [key]: 'Please enter valid email address'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "firstname") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter first name'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "last") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter last name'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "phone") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter phone number'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "location") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter location'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "password") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter password'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "confirmPassword") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter confirm password'
            })
        } else if(value !== user?.password){
            return setError({
                ...error,
                [key]: 'Please enter same as password'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    }
  }

  const dispatch = useDispatch();

  const submitAction = () => {
    dispatch(userCreate(user))
  }

  useEffect(() => {
    if(apiResponse?.type === 'success'){
        // document.getElementsByTagName('input')?.value('')
        setUser({
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            location: '',
            password: '',
            confirmPassword: '',
            category: 'React JS',
            status: 'Pending'
          });
    }
  }, [apiResponse])

  useEffect(() => {
    return () => dispatch(setApiResponse(null))
  }, [dispatch])

  return (
    <div className="page-100">
      <div className="section-50">
        <div className="vAlignMiddle">
            <div>
                <p><img src={VinShikLogo} alt="VinShik" style={{width: '250px'}} /></p>
                <img src={logo} className="App-logo" alt="logo" />
                <h1 style={{fontSize: '50px'}}>React JS Training</h1>
                <p style={{letterSpacing: '0.34rem', fontSize: '14px'}}>
                THEORIES | EXERCISES | PROJECTS
                </p>
            </div>
        </div>
      </div>
      <div className="section-50" style={{overflow: 'auto'}}>
        <div className="vAlignMiddle">
            <div>
                <div className='form-container'>
                    <h2>Sign Up to VinShik</h2><br />
                    {apiResponse && <div className={`notification ${apiResponse?.type}`}>
                        {apiResponse?.message}
                    </div>}
                    <div style={{textAlign: 'left'}}>
                        Add your detail:
                    </div>
                    <div>
                        <input type='text' placeholder='First Name' id='firstname' onBlur={(e) => updateUser(e, 'firstname')} />
                        {error?.firstname && <div className='form-error'>
                            {error?.firstname}
                        </div>}
                    </div>
                    <div>
                        <input type='text' placeholder='Last Name' id='lastname' onBlur={(e) => updateUser(e, 'lastname')} />
                        {error?.lastname && <div className='form-error'>
                            {error?.lastname}
                        </div>}
                    </div>
                    <div>
                        <input type='text' placeholder='Email' id='email' onBlur={(e) => updateUser(e, 'email')} />
                        {error?.email && <div className='form-error'>
                            {error?.email}
                        </div>}
                    </div>
                    <div>
                        <input type='text' placeholder='Phone' id='phone' onBlur={(e) => updateUser(e, 'phone')} />
                        {error?.phone && <div className='form-error'>
                            {error?.phone}
                        </div>}
                    </div>
                    <div>
                        <input type='text' placeholder='Location' id='location' onBlur={(e) => updateUser(e, 'location')} />
                        {error?.location && <div className='form-error'>
                            {error?.location}
                        </div>}
                    </div>
                    <div style={{textAlign: 'left'}}>
                        Set your password:
                    </div>
                    <div>
                        <input type='password' placeholder='Password' id='password' onBlur={(e) => updateUser(e, 'password')} />
                        {error?.password && <div className='form-error'>
                            {error?.password}
                        </div>}
                    </div>
                    <div>
                        <input type='password' placeholder='Confirm Password' id='confirmPassword' onBlur={(e) => updateUser(e, 'confirmPassword')} />
                        {error?.confirmPassword && <div className='form-error'>
                            {error?.confirmPassword}
                        </div>}
                    </div>
                    <div>
                        <button
                            onClick={submitAction}
                            disabled={
                                (
                                    user?.firstname &&
                                    user?.lastname &&
                                    user?.email &&
                                    user?.phone &&
                                    user?.location &&
                                    user?.password &&
                                    user?.confirmPassword &&
                                    !error?.firstname &&
                                    !error?.lastname &&
                                    !error?.email &&
                                    !error?.phone &&
                                    !error?.location &&
                                    !error?.password &&
                                    !error?.confirmPassword
                                ) ? false : true
                            }
                        >Sign Up</button>
                    </div>
                    <div>
                        Go to <Link to={'/login'}>Login</Link> / <Link to={'/'}>Home</Link>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
