import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = ({to}) => {
    const navigate = useNavigate();
    useEffect(()=>{
        navigate(to)
    }, [to])
    return null
}

export default Redirect;