import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { allReducers } from "./allReducers";
import { setToken, setUser } from "./reducerSlice/users";
import { userDetail } from "./actions/usersAction";
import { jwtDecode } from "jwt-decode";



const store = configureStore({
    reducer: {
      ...allReducers,
    //   reviews
    },
  });

  const token = localStorage.getItem('token');
  if(token){
    console.log('user-', jwtDecode(token))
    const {email} = jwtDecode(token)?.result
    store.dispatch(setToken(token))
    store.dispatch(userDetail({email}))
  }

  export default store;