import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import VinShikLogo from '../VinShik.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../store/actions/usersAction';
import { setApiResponse } from '../store/reducerSlice/users';
// import './App.css';

function Login() {
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState({
    email: '',
    password: ''
  });

  const apiResponse = useSelector(state => state?.users?.apiResponse);

  const updateUser = (e, key) => {
    console.log('-e-', e.target.value);
    const value = e?.target?.value.trim()
    setUser({
        ...user,
        [key]: value
    })
    if(key === "email") {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter email address'
            })
        } else if(!value.match(regex)){
            return setError({
                ...error,
                [key]: 'Please enter valid email address'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    } else if(key === "password") {
        if(!value){
            return setError({
                ...error,
                [key]: 'Please enter password'
            })
        } else {
            return setError({
                ...error,
                [key]: ''
            })
        }
    }
  }

  const dispatch = useDispatch();

  const submitAction = () => {
    dispatch(userLogin(user))
  }


  useEffect(() => {
    return () => dispatch(setApiResponse(null))
  }, [dispatch])

  return (
    <div className="page-100">
      <div className="section-50">
        <div className="vAlignMiddle">
            <div>
                <p><img src={VinShikLogo} alt="VinShik" style={{width: '250px'}} /></p>
                <img src={logo} className="App-logo" alt="logo" />
                <h1 style={{fontSize: '50px'}}>React JS Training</h1>
                <p style={{letterSpacing: '0.34rem', fontSize: '14px'}}>
                THEORIES | EXERCISES | PROJECTS
                </p>
            </div>
        </div>
      </div>
      <div className="section-50" style={{overflow: 'auto'}}>
        <div className="vAlignMiddle">
            <div>
                <div className='form-container'>
                    <h2>Login to VinShik</h2><br />
                    {apiResponse && <div className={`notification ${apiResponse?.type}`}>
                        {apiResponse?.message}
                    </div>}
                    <div>
                        <input type='text' placeholder='Email' id='email' onBlur={(e) => updateUser(e, 'email')} />
                        {error?.email && <div className='form-error'>
                            {error?.email}
                        </div>}
                    </div>
                    <div>
                        <input type='password' placeholder='Password' id='password' onBlur={(e) => updateUser(e, 'password')} />
                        {error?.password && <div className='form-error'>
                            {error?.password}
                        </div>}
                    </div>
                    <div>
                        <button onClick={submitAction} disabled={(user?.email && user?.password && !error?.email && !error?.password) ? false : true }>Login</button>
                    </div>
                    <div>
                        Go to <Link to={'/signup'}>Sign Up</Link> / <Link to={'/'}>Home</Link>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Login;